@font-face {
    font-family: 'Avenir';
    src: url("../assets/fonts/Avenir/Avenir-Book.woff2") format("woff2"), url("../assets/fonts/Avenir/Avenir-Book.woff") format("woff");
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'Avenir';
    src: url("../assets/fonts/Avenir/Avenir-Next-Medium.woff2") format("woff2"), url("../assets/fonts/Avenir/Avenir-Next-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: 'Avenir';
    src: url("../assets/fonts/Avenir/Avenir-Next-Bold.woff2") format("woff2"), url("../assets/fonts/Avenir/Avenir-Next-Bold.woff") format("woff");
    font-weight: 600;
    font-style: normal
}

@font-face {
    font-family: 'Sentinel';
    src: url("../assets/fonts/Sentinel/Sentinel-Light.woff2") format("woff2"), url("../assets/fonts/Sentinel/Sentinel-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal
}

@font-face {
    font-family: 'Sentinel';
    src: url("../assets/fonts/Sentinel/Sentinel-Book.woff2") format("woff2"), url("../assets/fonts/Sentinel/Sentinel-Book.woff") format("woff");
    font-weight: normal;
    font-style: normal
}