.footer {
  background: var(--white);
  padding: 40px 0;
  img.brand {
    height: 45px;
    margin-bottom: 20px;
  }
  p {
    font-size: 15px;
  }
  h4 {
    font-size: 16px;
    color: var(--primary);
    position: relative;
    font-weight: 600;
  }
  ul {
    list-style: none;
    padding: 0;
    margin-top: 10px;
    margin-bottom: 0;

    li {
      a {
        color: var(--text);
        display: inline-block;
        margin-bottom: 10px;
        span {
          display: inline-block;
          margin-right: 8px;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .ant-divider {
    border-top: 1px solid rgba(232, 232, 232, 1) !important;
  }

  // socialmedia
  .socialmedia {
    ul {
      li {
        img {
          width: 33px;
          height: 33px;
        }
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }

  // mabtree-helpline
  .mabtree-helpline {
    font-size: 16px;
    .list {
      padding-left: 35px;
      margin-bottom: 15px;
      .icon {
        left: 0;
        top: 1px;
        svg {
          color: var(--secondary);
        }
      }
      a {
        color: #009688;
        // color: var(--secondary);
      }
    }
  }
}

.ant-back-top {
  height: 35px !important;
  width: 35px !important;
  right: 23px !important;
  bottom: 12px !important;
  background: var(--primary);
  text-align: center;
  line-height: 2.5 !important;
  border-radius: 38px !important;
  color: var(--white) !important;
  svg {
    width: 18px;
  }
}
