@import "./mixin.scss";

// Ant Design Override
.ant-typography,
.ant-btn,
.ant-menu,
.ant-card,
.ant-modal,
.ant-col-xs-24,
.ant-col,
.ant-row,
.ant-layout {
  font-family: "Inter", sans-serif !important;
  font-size: 16px !important;
}

// ant layout
.ant-layout {
  background: var(--white) !important;
}
a.ant-btn {
  padding-top: 0 !important;
}
// Antd Button styles
.ant-btn {
  &a {
    padding-top: 4px !important;
  }
  .arrow-right {
    rotate: -40deg;
  }
}
.ant-btn,
.ant-picker,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  input {
  min-height: 40px;
  &.ant-btn-sm {
    min-height: 30px;
  }
  &.ant-btn-lg {
    min-height: 45px;
  }
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 40px;
}
.ant-btn-primary,
.ant-btn-default,
.ant-btn-secondary {
  border-radius: 5px;
  outline: none;
  box-shadow: unset;
}
.ant-btn-primary,
.ant-btn-primary:active {
  color: var(--white);
  background-color: var(--primary);
  border-color: var(--primary);
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: var(--primary-hover) !important;
  border-color: var(--primary-hover) !important;
}
.ant-btn-secondary,
.ant-btn-secondary:active {
  color: var(--text);
  background-color: var(--yellow);
  border-color: var(--yellow);
  border: 1px solid transparent;
}
.ant-btn-secondary:hover,
.ant-btn-secondary:focus {
  color: var(--text);
  background-color: var(--yellow-hover) !important;
  border-color: var(--yellow-hover) !important;
}
.ant-btn-default {
  color: var(--text);
  border: 1.5px solid var(--white);
  background-color: var(--white);
  &.white {
    border: 0;
  }
}

.ant-btn-default:hover,
.ant-btn-default:focus {
  border-color: var(--white) !important;
  color: var(--text) !important;
}

// tab =================
.ant-tabs-tab {
  font-size: 16px;
  font-weight: 500;

  &:hover,
  &:focus {
    color: var(--primary);
  }
  &.ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: var(--primary);
    }
  }
}

.ant-tabs-ink-bar {
  background: var(--primary);
}
