/*--Main Slider Style starts--*/
.mabtree-banner {
  position: relative;
  height: 100vh;

  @media (max-width: 550px) {
    height: auto;
  }

  .frame {
    position: absolute;
    border-radius: 4rem;
    width: calc(100% - 4rem);
    height: calc(100% - 9rem);
    left: 2rem;
    top: 2rem;
    overflow: hidden;

    @media (max-width: 550px) {
      position: relative;
      border-radius: 1rem;
      height: 153px;
      left: 2rem;
      top: unset;
      bottom: 0;
      z-index: 99;
      margin-top: 10px;
    }

    video {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      overflow: hidden;

      @media (max-width: 500px) {
        position: relative;
        top: 0;
        left: 0;
        transform: unset;
        min-width: 100%;
        min-height: 100%;
        width: 101%;
        height: auto;
        overflow: hidden;
      }
    }
  }

  .top-left {
    position: relative;
    background: url("../../assets/images/element-top-left.svg") no-repeat 100%
      100%;
    background-size: 133%;
    padding: 3em 5rem 12rem 5rem;
    margin-right: 1rem;
    z-index: 9;
    height: 106%;
    width: 45%;

    @media (max-width: 980px) {
      width: 66%;
      margin-right: 0;
      background-size: 129%;
      padding: 2rem 2rem 10rem;
    }

    @media (max-width: 550px) {
      width: 100%;
    }

    h1 {
      font-size: 3.6rem;

      @media (max-width: 990px) {
        font-size: 2.6rem;
      }

      @media (max-width: 550px) {
        font-size: 1.8rem;
      }
    }

    p {
      font-size: 1.3rem;
      line-height: 140%;
    }
  }
}

// unlock-opportunities
.unlock-opportunities {
  .details {
    @media (max-width: 400px) {
      text-align: center;
    }
  }
}

// Saving section ===========
.savings {
  padding-top: 2rem;
  padding-bottom: 6rem;

  @media (max-width: 550px) {
    padding-bottom: 4rem;
  }

  img {
    height: 80px;
  }
}

// our vision section
.our-vision {
  position: relative;

  &.spacing {
    @media (max-width: 400px) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .overlay {
    background: url(../../assets/images/antibodies.jpg) no-repeat 50% 50%;
    background-size: cover;
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;
    opacity: 0.5 !important;

    @media (max-width: 400px) {
      width: 100%;
      position: relative;
      min-height: 200px;
    }
  }

  .content {
    width: 43%;

    @media (max-width: 400px) {
      width: 100%;
    }
  }

  .container {
    @media (max-width: 400px) {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }
}

.partnering {
  .left-spacing {
    @media (max-width: 400px) {
      padding-left: 0;
    }
  }

  .top-spacing {
    @media (max-width: 400px) {
      padding-top: 1rem;
    }
  }
}
