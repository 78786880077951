@import "../../Style/mixin.scss";

.mabtree-carousel {
  .main-banner {
    .banner-height {
      min-height: 560px;
    }
    .content {
      position: relative;
      width: 45%;
      z-index: 9;
      @media (max-width: 800px) {
        width: 80%;
      }
      @media (max-width: 500px) {
        text-align: center;
        width: 100%;
      }
      h1 {
        @media (max-width: 800px) {
          font-size: 2rem;
          line-height: 120%;
        }
      }
      p {
        font-size: 19px;
        @media (max-width: 700px) {
          font-size: 14px;
        }
      }
    }
    .banner-gradient {
      background-image: -webkit-gradient(
        linear,
        left top,
        right top,
        from(rgba(41, 57, 72, 0.9)),
        color-stop(60%, hsla(0, 0%, 100%, 0))
      );
      background-image: linear-gradient(
        90deg,
        rgba(41, 57, 72, 0.9),
        hsla(0, 0%, 100%, 0) 60%
      );
    }
    .banner-gradient {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background-size: cover;
      background-position: 50%;
    }
  }
  // prev-next-nav
  .prev-next-nav {
    position: absolute;
    bottom: 30px;
    right: 40px;
    font-size: 15px;
    @media (max-width: 500px) {
      right: 0;
      left: 0;
      margin: 0 auto;
      text-align: center;
      width: 30%;
      font-size: 11px;
    }
  }

  // slic slide dots
  .ant-carousel .slick-dots li button {
    background-color: var(--secondary);
    opacity: 0.9;
  }
  .ant-carousel-vertical {
    .slick-dots-right {
      right: 45px;
      @media (max-width: 800px) {
        right: 10px;
      }
    }
  }
}

.banner1 {
  background-image: url("../../assets/images/banner/patient.jpg");
  @include backgroundImage;
}
.banner2 {
  background-image: url("../../assets/images/banner/revolutionizing-treatment-with-monoclonal-antibodies.jpg");
  @include backgroundImage;
}
.banner3 {
  background-image: url("../../assets/images/banner/bridging-the-gap-in-healthcare.jpg");
  @include backgroundImage;
}

.banner4 {
  background-image: url("../../assets/images/banner/innovating-for-a-healthier-tomorrow.jpg");
  @include backgroundImage;
}

.banner5 {
  background-image: url("../../assets/images/banner/mabtree-biologics-ag.jpg");
  @include backgroundImage;
}
