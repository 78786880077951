.breadcrum-placehlder {
  h1 {
    font-size: 2.5rem;
    line-height: 150%;
    @media (max-width: 900px) {
      font-size: 1.6rem;
    }
    @media (max-width: 500px) {
      font-size: 1.3rem;
    }
  }
  overflow: hidden;
  &.bg-patterns {
    background-color: #415364;
  }
  .content {
    width: 44%;
    height: 300px;
    position: relative;
    display: flex;
    align-items: center;
    @media (max-width: 600px) {
      width: 60%;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      z-index: 99;
    }
    &::before {
      background-color: transparent;
      bottom: 0;
      right: -499px;
      border-bottom: 500px solid transparent;
      border-left: 500px solid #415364;
      z-index: 3;
    }
    &::after {
      background-color: var(--primary);
      height: 100px;
      width: 200px;
      -webkit-transform: skew(0, -45deg);
      transform: skew(0, -45deg);
      bottom: -10px;
      right: -189px;
      z-index: 2;
      @media (max-width: 600px) {
        height: 59px;
        width: 154px;
        transform: skew(0, -45deg);
        bottom: -10px;
        right: -122px;
        z-index: 2;
      }
    }
  }
  .image {
    top: 0;
    right: 0;
    height: 300px;
    width: 50%;
    object-fit: cover;
    @media (max-width: 600px) {
      width: 40%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.page-path {
  position: absolute;
  bottom: 30px;
  .ant-breadcrumb-separator {
    color: #e4e4e4;
  }
  .ant-breadcrumb a {
    color: var(--secondary);
  }
  .ant-breadcrumb li:last-child a {
    color: var(--white);
  }
}
