.faq {
    .ant-collapse-item {
        .ant-collapse-header {
            font-size: 1.2rem;
            font-weight: 500;
        }

        .ant-collapse-content-box {
            p {
                font-size: 1rem;
            }
        }
    }
}