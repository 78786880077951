@mixin flexDesign(
  $alignItem: center,
  $justifyContent: center,
  $flexDirection: row
) {
  display: flex;
  align-items: $alignItem;
  justify-content: $justifyContent;
  flex-direction: $flexDirection;
}
@mixin imageSelectnone {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

@mixin greentextStoke {
  color: transparent;
  -webkit-text-fill-color: transparent;
  /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--secondary);
}

@mixin backgroundImage(
  $size: cover,
  $position: center center,
  $repeat: no-repeat
) {
  background-size: $size;
  background-position: $position;
  background-repeat: $repeat;
}

// common fonts and styles for application
@mixin font1 {
  font-size: 18px;
  font-weight: var(--semibold);
  line-height: 28px;
}

@mixin font2 {
  font-size: 16px;
  font-weight: var(--regular);
  line-height: 24px;
}

@mixin font3 {
  font-size: 16px;
  font-weight: var(--medium);
  line-height: 24px;
}

@mixin font4 {
  font-size: 14px;
  font-weight: var(--regular);
  line-height: 25px;
}

@mixin font5 {
  font-size: 14px;
  font-weight: var(--semibold);
  line-height: 20px;
}

@mixin font6 {
  font-size: 12px;
  font-weight: var(--regular);
  line-height: 18px;
}
