@import "../../Style/mixin.scss";

.contact-card {
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.08);
  background-color: var(--white);

  ul {
    list-style: none;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 0;

    li {
      a {
        display: inline-block;
        margin-bottom: 10px;
        color: #343434;

        span {
          display: inline-block;
          margin-right: 8px;
        }
      }
    }
  }
}

.contact-form {
  form {
    input,
    textarea {
      padding: 10px 20px;
      border-radius: 0.2rem;
      border: 1px solid #c2c2c2;
      margin-bottom: 1rem;
      width: 100%;
    }
  }
}

.contact-form-section {
  @media (max-width: 500px) {
    padding: 4rem 0;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.3;
  }
  .form-section {
    padding: 40px;
    background: rgba(2, 126, 111, 0.67);
  }
}

.oncology-immunology-banner {
  background-image: url("../../assets/images/oncology-immunology.jpg");
  @include backgroundImage;
}
