@import "./mixin.scss";

*,
:after,
:before {
  box-sizing: inherit;
}
:root {
  --box-shadow: 0 4px 7px rgba(0, 0, 0, 0.08), 0 2px 2px rgba(0, 0, 0, 0.06);
  --light-gray: #f7f7ff;
  --light-blue: #f1f2ff;

  // primary colors
  --primary: #027e6f;
  --primary-hover: #005d5f;
  --secondary: #dee33e;
  --yellow: #ffc107;
  --yellow-hover: #edb100;

  // white and black
  --white: #fff;
  --black: #000;

  // font color
  --text: #1f243c;
  --text-second: #5a6183;

  //   font family
  --inter: "Inter", sans-serif;

  // font-weight
  --regular: 400;
  --medium: 500;
  --semibold: 600;
  --bold: 700;
}

body {
  counter-reset: section;
  overflow-x: hidden;
}

html,
body {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--text);
  font-family: var(--inter) !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--inter) !important;
  font-weight: var(--bold);
}

img {
  @include imageSelectnone;
}

// heading tags with font size
h1 {
  font-size: 48px;
  line-height: 54px;
  @media (max-width: 700px) {
    font-size: 30px;
    line-height: 120%;
  }
}

h2 {
  font-size: 29px;
  line-height: 38px;
  @media (max-width: 700px) {
    font-size: 25px;
    line-height: 140%;
  }
}

h3 {
  font-size: 24px;
  line-height: 39px;
  @media (max-width: 700px) {
    font-size: 20px;
    line-height: 140%;
  }
}

h4 {
  font-size: 18px;
  line-height: 28px;
  @media (max-width: 700px) {
    font-size: 16px;
    line-height: 140%;
  }
}

h5,
h6 {
  font-size: 16px;
  line-height: 23px;
  @media (max-width: 700px) {
    font-size: 14px;
    line-height: 140%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
p {
  margin-top: 0;
}
p {
  line-height: 184%;
}
b,
strong {
  font-weight: 500;
}

a {
  outline: none !important;
  text-decoration: none !important;
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  /*--Drag--*/
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

// container
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 2.5rem;
}

// font family
.sen-font {
  font-family: "Sentinel", sans-serif;
}

.av-font {
  font-family: "Avenir", "Open Sans", Helvetica, Arial, sans-serif;
}

// flex design
.flex-design {
  @include flexDesign($justifyContent: center);
}

// z-index ===================================
.z-index {
  z-index: 9;
}
// Title section =============================

.title {
  margin-bottom: 5rem;
  font-size: 3.5rem;
}
// colors =================================
.text-white {
  color: var(--white);
}
.text-black {
  color: var(--text) !important;
}
.text-gray {
  color: var(--text-secondary);
}

.text-primary {
  color: var(--primary) !important;
}

.text-green {
  color: var(--secondary);
}

.text-green-stroke {
  @include greentextStoke;
}

// background colors ==============================

.bg-green {
  background-color: var(--primary);
}

.bg-white {
  background-color: var(--white);
}

.bg-primary {
  background-color: var(--primary) !important;
}

.bg-light-gray {
  background-color: var(--light-gray);
}
.bg-light-blue {
  background-color: var(--light-blue);
}
.bg-light-background {
  background-color: #f1f6f8;
}

// width ==========================================
.width-80 {
  width: 80%;
  @media (max-width: 900px) {
    width: 100%;
  }
}

// mabtree-pattern =================================
.mabtree-pattern {
  background-image: url("../assets/images/pattern/immune-pattern-diff-color.svg");
  background-size: 81px;
  background-repeat: repeat;
  width: 201px;
  height: 95px;
}

// circle pattern
.circle-pattern {
  background-image: url("../assets/images/pattern/immune-circle.svg");
  background-size: 350px;
  background-repeat: no-repeat;
  width: 350px;
  height: 350px;
  &.top-150 {
    top: -150px;
  }
  &.bottom-150 {
    bottom: -150px;
  }

  &.start-150 {
    left: -150px;
  }
  &.end-150 {
    right: -150px;
  }

  @media (max-width: 700px) {
    opacity: 0.5;
  }
}

// viewport height =================================

.viewport-height {
  min-height: 100vh;
}

// cursor pointer ================================
.cursor-pointer {
  cursor: pointer;
}

// font-size================
.lead {
  @media (max-width: 700px) {
    font-size: 16px;
  }
}
.font-1 {
  @include font1;
}

.font-2 {
  @include font2;
}

.font-3 {
  @include font3;
}

.font-4 {
  @include font4;
}

.font-5 {
  @include font5;
}

.font-6 {
  @include font6;
}
// font weight =================================

.font-weight-normal {
  font-weight: var(--regular) !important;
}
.font-weight-medium {
  font-weight: var(--medium);
}
.font-weight-semibold {
  font-weight: var(--semibold);
}
.font-weight-bold {
  font-weight: bold;
}

// button styles ===================================
.btn-lg {
  font-size: 16px;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn-outline-dark {
  color: var(--dark);
  border-color: var(--dark);
}

.btn-outline-warning {
  color: var(--yellow);
  border-color: var(--yellow);
}

.btn-warning {
  color: var(--black);
  background-color: var(--yellow);
  border-color: var(--yellow);
}

// spacing ========================================
.spacing {
  padding-top: 4rem;
  padding-bottom: 4rem;
  @media (max-width: 700px) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.spacing-bottom {
  padding-bottom: 4rem;
  @media (max-width: 700px) {
    padding-bottom: 3rem;
  }
}

.top-spacing {
  padding-top: 4rem;
  @media (max-width: 700px) {
    padding-top: 3rem;
  }
}

.left-spacing {
  padding-left: 3rem;
  @media (max-width: 700px) {
    padding-left: 2rem;
  }
}
