@import "../Style/mixin.scss";

.header {
  background-color: var(--white);
  box-shadow: unset;
  transition: all 0.5s ease-in-out;
  width: 100%;
  z-index: 100;
  padding: 1rem;

  .flex-menu {
    @include flexDesign($justifyContent: space-between);
  }

  .brand {
    .white-logo {
      display: none;
    }

    img {
      height: 48px;

      @media (max-width: 500px) {
        height: 37px;
      }
    }
  }

  .right-menu {
    @media (max-width: 990px) {
      display: none;
      position: fixed;
      top: 0;
      right: 0;
      width: 300px;
      height: 100%;
      background: linear-gradient(177deg, #269496, #005d5f);
      padding: 49px 20px;
    }

    @media (max-width: 500px) {
      width: 68%;
    }

    &.active {
      display: block;
    }

    ul {
      @include flexDesign($justifyContent: space-between);
      list-style: none;
      padding: 0;
      margin: 0;

      @media (max-width: 990px) {
        display: block;
      }

      li {
        position: relative;

        &:hover {
          ul {
            display: block;
            padding: 20px;
            background-color: var(--black);
            border-radius: 5px;
            width: 200px;
            position: absolute;
            top: 29px;
            left: 0;
            box-shadow: 8px 8px 1px #0e0e0e;

            li {
              a {
                display: block;
                color: var(--black);
                padding: 0.5rem 0;
                &:hover {
                  color: var(--primary);
                }

                @media (max-width: 990px) {
                  text-align: left;
                  font-size: 1.3rem;
                }
              }
            }
          }
        }

        ul {
          display: none;
        }

        a {
          padding: 0.5rem 1rem;
          color: var(--black);
          display: block;
          text-align: center;

          @media (max-width: 990px) {
            text-align: left;
            font-size: 1.3rem;
            color: var(--white);
          }

          &.parent-submenu::after {
            display: inline-block;
            margin-left: 0.255em;
            vertical-align: 0.255em;
            content: "";
            border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-bottom: 0;
            border-left: 0.3em solid transparent;
          }
        }
      }

      li {
        ul {
          display: none;
        }
      }
    }
  }

  .cta {
    .btn-outline-dark {
      color: var(--white);
      border-color: var(--white);

      @media (max-width: 900px) {
        color: var(--white) !important;
        border-color: #ffc107 !important;
        background-color: #ffc107 !important;
      }
    }

    padding-left: 20px;

    @media (max-width: 990px) {
      padding: 0 1rem;
      text-align: center;

      margin-top: 20px;

      a {
        width: 100%;
      }
    }
  }
}

.hamburger-menu {
  display: none;

  @media (max-width: 900px) {
    display: block;
    z-index: 9;
  }
}
