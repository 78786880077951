.current-jobs {
  .job-list {
    .job-details {
      padding: 20px;
      background: var(--white);
      min-height: 161px;
      border: 1px solid #9f9f9f;
      border-top: 0;

      @media (max-width: 500px) {
        min-height: auto;
      }

      h2 {
        font-size: 15px;
        line-height: 167%;
      }

      .qualification {
        margin-bottom: 10px;

        span {
          font-weight: 500;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
